<template>
  <div :class="customClass">
    <div class="elevation-2">
      <div class="dashboard-bar">
        <div v-if="!editMode">
          <div class="dashboard-tabs-area">
            <v-tabs v-model="selectedTab" class="dashboard-tabs">
              <v-tab v-for="(dashboard, i) in dashboards" :key="i" class="dashboard-tab" :disabled="!finishedLoading">
                <div>{{ dashboard.name }}</div>
                <div v-if="i === selectedTab" class="edit-buttons">
                  <x-btn icon="mdi-pencil" @click="enableEditMode(false)"/>
                  <x-btn
                      v-if="selectedDashboardId && dashboards.length > 1"
                      icon="mdi-delete"
                      @click="reallyDeleteDialog = true"/>
                </div>
              </v-tab>
            </v-tabs>
            <XBtn icon="mdi-plus" @click="openCreateDashboardDialog" :disabled="!finishedLoading"/>
          </div>
          <div class="date-time-range-picker-container">
            <DateTimeRangeAndRefresh v-model="rangeAndRefresh" :max="new Date()"/>
          </div>
        </div>
        <div v-else class="dashboard-bar-edit-mode">
          <object-input-row v-model="dashboardToEdit" :columns="editColumns"/>
        </div>
      </div>
      <XGridLayout
          v-model="dashboardToEdit.items"
          :col-num="12"
          :row-height="30"
          :is-draggable="editMode"
          :is-resizable="editMode"
          :margin="[10, 10]"
          :use-css-transforms="true"
          :item-resizable="editMode"
          class="dashboard-grid">
        <template v-slot="{item, size}">
          <DashboardWidget
              :value="item"
              :edit-mode="editMode"
              :size="size"
              :range="rangeAndRefresh.range"
              :refresh="rangeAndRefresh.refresh"
              :reload="reload"
              @edit="openWidgetDialog"
              @delete="deleteWidget"
              @loading="handleLoading($event, item.i)"/>
        </template>
      </XGridLayout>
    </div>
    <yes-no-dialog
        v-model="createDashboardDialog"
        title="Create dashboard"
        yes-text="Create"
        no-text="Cancel"
        width="500"
        @yes="enableEditMode(true)">
      <x-select
          v-model="selectedTemplate"
          label="Template"
          :items="dashboardTemplates"
          item-value="id"
          item-text="name"
          required/>
    </yes-no-dialog>
    <really-delete-dialog
        v-model="reallyDeleteDialog" @yes="deleteDashboard" :item-name="selectedDashboardName"/>
    <dashboard-widget-dialog v-model="widgetDialog" :widget="widget" @save="handleWidgetSave"/>
  </div>
</template>
<script>
import XBtn from '@/components/basic/XBtn';
import ObjectInputRow from '@/components/basic/ObjectInputRow';
import YesNoDialog from '@/components/extended/YesNoDialog';
import XSelect from '@/components/basic/XSelect';
import ReallyDeleteDialog from '@/components/extended/ReallyDeleteDialog';
import XGridLayout from '@/components/basic/XGridLayout';
import DashboardWidgetDialog from '@/components/specific/DashboardWidgetDialog';
import DashboardWidget from '@/components/specific/DashboardWidget';
import DateTimeRangeAndRefresh from '@/components/basic/DateTimeRangeAndRefresh';

export default {
  name: 'Dashboard',
  components: {
    DateTimeRangeAndRefresh,
    DashboardWidget,
    DashboardWidgetDialog,
    XGridLayout,
    ReallyDeleteDialog,
    XSelect,
    YesNoDialog,
    ObjectInputRow,
    XBtn,
  },
  props: ['additional'],
  data() {
    return {
      dashboards: [],
      editMode: false,
      dashboardToEdit: null,
      selectedTab: 0,
      createDashboardDialog: false,
      reallyDeleteDialog: false,
      widgetDialog: false,
      widget: null,
      selectedTemplate: 0,
      rangeAndRefresh: {
        range: {
          from: new Date(new Date().getTime() - 86400000),
          to: new Date(),
          seconds: 0,
        },
        refresh: 0,
      },
      editColumns: [
        {
          type: 'text',
          label: 'Name',
          value: 'name',
          required: true,
        },
        {
          type: 'button',
          text: 'Add widget',
          icon: 'mdi-plus',
          color: 'primary',
          disabled: (row) => {
            return !row.name;
          },
          click: () => this.openWidgetDialog(null),
        },
        {
          type: 'button',
          text: 'Save',
          icon: 'mdi-content-save',
          color: 'primary',
          disabled: (row) => {
            return !row.name;
          },
          click: () => this.disableEditMode(true),
        },
        {
          type: 'button',
          text: 'Cancel',
          icon: 'mdi-cancel',
          color: 'primary',
          click: () => this.disableEditMode(false),
        },
      ],
      loadingMap: [],
      finishedLoading: false,
      reload: false,
    };
  },
  watch: {
    selectedTab: {
      immediate: true,
      handler() {
        this.reload = true;
        this.dashboardToEdit = this.selectedDashboard;
        this.$nextTick(() => {
          this.reload = false;
        });
      },
    },
    dashboardToEdit(value) {
      const loadingMap = {};
      for (const item of value.items) {
        loadingMap[item.i] = false;
      }
      this.loadingMap = loadingMap;
    },
  },
  created() {
    this.getDashboards();
  },
  computed: {
    customClass() {
      let className = 'dashboard';
      if (typeof this.additional.userInfo !== 'undefined' && typeof this.additional.userInfo.isDemoProject !== 'undefined' && this.additional.userInfo.isDemoProject === true) {
        className = 'dashboard mt-16';
      }
      return className;
    },
    dashboardTemplates() {
      return [
        {
          id: 0,
          name: 'Default',
          items: [],
        },
      ].concat(this.dashboards);
    },
    selectedDashboard() {
      const selectedDashboard = this.dashboards[this.selectedTab];
      if (selectedDashboard) return selectedDashboard;
      return this.deepCopy(this.dashboardTemplates[0]);
    },
    selectedDashboardName() {
      const selectedDashboard = this.dashboards[this.selectedTab];
      if (selectedDashboard) return selectedDashboard.name;
      return '';
    },
    selectedDashboardId() {
      const selectedDashboard = this.dashboards[this.selectedTab];
      if (selectedDashboard) return selectedDashboard.id;
      return 0;
    },
    currentItems() {
      return this.dashboardToEdit.items;
    },
  },
  methods: {
    getDashboards() {
      this.services.chart.getDashboardList((dashboards) => {
        for (let i = 0; i < dashboards.length; i++) {
          if (!dashboards[i].name) {
            if (dashboards.length > 1) dashboards[i].name = `Dashboard ${i}`;
            else dashboards[i].name = 'Dashboard';
          }
        }
        if (!dashboards.length) {
          const defaultDashboard = this.deepCopy(this.dashboardTemplates[0]);
          defaultDashboard.name = 'Dashboard';
          dashboards = [defaultDashboard];
          this.finishedLoading = true;
        }
        this.dashboards = dashboards;
        this.dashboardToEdit = this.deepCopy(this.selectedDashboard);
      });
    },
    openCreateDashboardDialog() {
      this.selectedTemplate = 0;
      this.createDashboardDialog = true;
    },
    enableEditMode(newDashboard) {
      if (newDashboard) {
        const dashboardToEdit = this.deepCopy(this.dashboardTemplates.find(x => x.id === this.selectedTemplate));
        if (dashboardToEdit.name === 'Default') dashboardToEdit.name = `Dashboard ${this.dashboards.length + 1}`;
        dashboardToEdit.id = 0;
        this.dashboardToEdit = dashboardToEdit;
      } else {
        this.dashboardToEdit = JSON.parse(JSON.stringify(this.selectedDashboard));
      }
      this.editMode = true;
    },
    disableEditMode(saveChanges) {
      if (saveChanges) {
        if (!this.dashboardToEdit.id) {
          this.putRequest(`${process.env.VUE_APP_CHART_SERVICE}/chart-service/v3/dashboard`, this.dashboardToEdit,
              (id) => {
                this.dashboardToEdit.id = id;
                this.dashboards.push(this.dashboardToEdit);
                this.editMode = false;
                this.selectedTab = this.dashboards.length - 1;
              });
        } else {
          this.patchRequest(`${process.env.VUE_APP_CHART_SERVICE}/chart-service/v3/dashboard`, this.dashboardToEdit,
              () => {
                this.dashboards[this.dashboards.findIndex(
                    x => x.id === this.dashboardToEdit.id)] = this.dashboardToEdit;
                this.editMode = false;
              });
        }
      } else {
        this.editMode = false;
        this.dashboardToEdit = this.deepCopy(this.selectedDashboard);
      }
    },
    deleteDashboard() {
      this.deleteRequest(`${process.env.VUE_APP_CHART_SERVICE}/chart-service/v3/dashboard/${this.selectedDashboardId}`,
          null, () => {
            this.dashboards.splice(this.selectedTab, 1);
          });
    },
    removeItem: function (value) {
      console.log(`Removing ${value}.`);
    },
    editGraph(i, valueLine) {
      console.log(i, valueLine);
    },
    handleWidgetSave(widget) {
      if (widget.i !== undefined) {
        const index = this.dashboardToEdit.items.findIndex(x => x.i === widget.i);
        if (index >= 0) {
          const dashboardWidget = this.dashboardToEdit.items[index];
          widget.x = dashboardWidget.x;
          widget.y = dashboardWidget.y;
          widget.w = dashboardWidget.w;
          widget.h = dashboardWidget.h;
          widget.i = dashboardWidget.i;
          this.$set(this.dashboardToEdit.items, index, widget);
        }
      } else {
        let w = 2;
        if (widget.type === 'teststatus') w = 8;
        const newWidget = this.deepCopy(widget);
        newWidget.x = 0;
        newWidget.y = 0;
        newWidget.w = w;
        newWidget.h = 5;
        newWidget.i = this.dashboardToEdit.items.length;
        this.dashboardToEdit.items.push(newWidget);
      }
    },
    deleteWidget(i) {
      this.dashboardToEdit.items.splice(this.dashboardToEdit.items.findIndex(x => x.i === i), 1);
    },
    openWidgetDialog(i) {
      let widget = null;
      if (i !== null) {
        widget = this.deepCopy(this.dashboardToEdit.items.find(x => x.i === i));
      }
      this.widget = widget;
      this.widgetDialog = true;
    },
    handleLoading(value, i) {
      if (value) this.finishedLoading = false;
      this.loadingMap[i] = value;
      if (!Object.values(this.loadingMap).includes(true)) this.finishedLoading = true;
    },
  },
};
</script>

<style scoped>
.dashboard {
  margin-bottom: 100px;
}

.dashboard-bar {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dashboard-bar > div {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.dashboard-tabs-area {
  display: flex;
  align-items: center;
}

.dashboard-tabs {
  width: auto;
  flex: 0 1 auto;
}

.dashboard-bar-edit-mode {
  padding: 10px;
  gap: 10px;
}

.dashboard-toolbar {
  width: 100%;
  height: 60px;
  padding: 0 10px;
}

.dashboard-toolbar >>> .v-toolbar__content {
  align-items: center;
  padding: 0;
  gap: 10px;
  align-content: space-between;
}

.dashboard-tab {
  display: flex;
  gap: 10px;
}

.edit-buttons {
  display: flex;
}

.dashboard-grid {
  background-color: #f1f0f0;
}

.dashboard-grid >>> .vue-resizable-handle {
  z-index: 1000;
}

.date-time-range-picker-container {
  padding: 0 10px;
}
</style>
