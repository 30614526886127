<template>
  <FormDialog
      :value="value"
      :item="dataWidget"
      title="Configure widget"
      width="740"
      @input="$emit('input', $event)"
      @save="emitSave"
      :disabled="!chartTypes.length || !meta">
    <template #dialog-content>
      <div class="dashboard-widget-form">
        <XSelect v-model="dataWidget.type" label="Type" :items="types" required/>
        <XTextField v-model="dataWidget.name" label="Title" max-length="30"/>
        <template v-if="dataWidget.type === 'echart'">
          <XSelect
              v-model="dataWidget.chartId"
              label="Chart Template"
              :items="chartTypes"
              item-value="id"
              item-text="name"
              required/>
          <div v-if="dataWidget.type === 'echart'">
            <HeadlineBox v-if="meta && meta[selectedMetric]" headline="Filters">
              <ListEditor v-model="dataWidget.filter" :columns="filterColumns"/>
            </HeadlineBox>
            <XThrobber v-else centered/>
          </div>
          <XCheckbox v-model="dataWidget.preview" label="Preview"/>
        </template>
        <div v-if="dataWidget.type === 'teststatus'" class="test-status-checkboxes">
          <XTextField label="Search" v-model="dataWidget.search"/>
          <XCheckbox label="Show test name" v-model="dataWidget.showTestName"/>
          <XCheckbox label="Show legend" v-model="dataWidget.showLegend"/>
        </div>
        <div v-if="dataWidget.type === 'echart' && userHasExternalLinkPermission" class="external-link">
          <InputRow v-if="externalLink">
            <XTextField :value="externalLink" label="External link" readonly class="external-link-text-field"/>
            <XBtn icon="mdi-delete" color="red" @click="reallyDeleteDialog = true"/>
          </InputRow>
          <InputRow>
            <XBtn
                text="Create new external link"
                icon="mdi-link-variant-plus"
                color="primary"
                @click="handleCreateExternalLink"/>
            <XBtn
                v-if="externalLink"
                text="Copy to clipboard"
                icon="mdi-content-copy"
                color="primary"
                @click="copyToClipboard"/>
            <XBtn
                v-if="externalLink"
                text="Open in new tab"
                icon="mdi-open-in-new"
                color="primary"
                @click="openInNewTab"/>
          </InputRow>
        </div>
      </div>
      <YesNoDialog
          v-model="confirmDialog"
          title="Confirm"
          text="This will invalidate the existing external link. Proceed?"
          @yes="createExternalLink"/>
      <ReallyDeleteDialog v-model="reallyDeleteDialog" @yes="deleteExternalLink"/>
      <LoadingDialog v-model="loading"/>
    </template>
  </FormDialog>
</template>

<script>
import XSelect from '@/components/basic/XSelect';
import FormDialog from '@/components/extended/FormDialog';
import XTextField from '@/components/basic/XTextField';
import HeadlineBox from '@/components/basic/HeadlineBox';
import ListEditor from '@/components/basic/ListEditor';
import XCheckbox from '@/components/basic/XCheckbox';
import XBtn from '@/components/basic/XBtn.vue';
import InputRow from '@/components/basic/InputRow.vue';
import XThrobber from '@/components/basic/XThrobber.vue';
import LoadingDialog from '@/components/basic/LoadingDialog.vue';
import YesNoDialog from '@/components/extended/YesNoDialog.vue';
import ReallyDeleteDialog from '@/components/extended/ReallyDeleteDialog.vue';

export default {
  name: 'DashboardWidgetDialog',
  components: {
    ReallyDeleteDialog,
    YesNoDialog,
    LoadingDialog,
    XThrobber,
    InputRow,
    XBtn,
    XCheckbox,
    ListEditor,
    HeadlineBox,
    XTextField,
    FormDialog,
    XSelect,
  },
  props: {
    value: Boolean,
    widget: Object,
  },
  data() {
    return {
      dataWidget: this.getDefaultWidget(),
      types: [
        {
          value: 'info',
          text: 'Info',
        },
        {
          value: 'echart',
          text: 'Chart',
        },
        {
          value: 'teststatus',
          text: 'Test Status',
        },
      ],
      chartTypes: [],
      meta: {},
      filterColumns: [
        {
          label: 'Tag',
          type: 'select',
          value: 'tag',
          items: () => {
            if (!this.chartTypes.length || !this.meta) return [];
            const tags = this.meta[this.selectedMetric].tags;
            if (!tags) return [];
            return Object.keys(tags);
          },
          required: true,
        },
        {
          label: 'Value',
          type: 'select',
          value: 'value',
          items: (row) => {
            if (!this.chartTypes.length || !this.meta) return [];
            const tags = this.meta[this.selectedMetric].tags;
            if (!tags) return [];
            return tags[row.tag];
          },
        },
      ],
      newDataWidget: false,
      externalLink: '',
      confirmDialog: false,
      reallyDeleteDialog: false,
      loading: false,
    };
  },
  watch: {
    value() {
      if (this.chartTypes.length) return;
      this.getRequest(`${process.env.VUE_APP_CHART_SERVICE}/chart-service/v3/chart-config-list`, null, (chartTypes) => {
        this.chartTypes = chartTypes;
      });
      this.getRequest(`${process.env.VUE_APP_CHART_SERVICE}/chart-service/v3/chart-config-meta`, null, (meta) => {
        this.meta = meta;
      });
    },
    widget: {
      immediate: true,
      handler(value) {
        this.externalLink = '';
        if (!value) this.dataWidget = this.getDefaultWidget();
        else if (this.dataWidget !== value) {
          if (value.filter) {
            value.filter = this.objectToArray(value.filter, 'tag');
          } else {
            value.filter = [];
          }
          this.dataWidget = value;
        }

        if (value && this.dataWidget.chartId && this.userHasExternalLinkPermission) {
          this.services.chart.getExternalLink(this.dataWidget.chartId, (externalLink) => {
            this.updateExternalLink(externalLink);
          });
        }
      },
    },
    dataWidget: {
      immediate: true,
      handler() {
        this.newDataWidget = true;
      },
    },
    'dataWidget.chartId'() {
      if (!this.newDataWidget) this.dataWidget.filter = [];
      this.newDataWidget = false;
    },
    'dataWidget.type'(value) {
      if (value === 'echart') {
        if (this.dataWidget.preview === undefined) this.dataWidget.preview = false;
      } else {
        delete this.dataWidget.preview;
      }

      if (value === 'teststatus') {
        if (this.dataWidget.search === undefined) this.dataWidget.search = '';
        if (this.dataWidget.showTestName === undefined) this.dataWidget.showTestName = true;
        if (this.dataWidget.showLegend === undefined) this.dataWidget.showLegend = true;
      } else {
        delete this.dataWidget.search;
        delete this.dataWidget.showTestName;
        delete this.dataWidget.showLegend;
      }
    },
  },
  computed: {
    selectedMetric() {
      const chartType = this.chartTypes.find(x => x.id === this.dataWidget.chartId);
      if (chartType) return chartType.metric;
      return '';
    },
    userHasExternalLinkPermission() {
      return this.$store.state.role && this.$store.state.role <= 2;
    },
  },
  methods: {
    getDefaultWidget() {
      return {
        type: '',
        name: '',
        chartId: 0,
        filter: [],
        preview: false,
      };
    },
    emitSave() {
      const widget = this.deepCopy(this.dataWidget);
      widget.filter = this.arrayToObject(widget.filter, 'tag');
      this.$emit('save', widget);
    },
    handleCreateExternalLink() {
      if (!this.externalLink) {
        this.createExternalLink();
      } else {
        this.confirmDialog = true;
      }
    },
    createExternalLink() {
      this.loading = true;
      this.services.chart.createExternalLink(this.dataWidget.chartId, this.dataWidget.filter, (response) => {
        this.updateExternalLink(response.link);
        this.loading = false;
      });
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.externalLink);
      this.showNotification('Copied to clipboard.');
    },
    openInNewTab() {
      window.open(this.externalLink, '_blank').focus();
    },
    deleteExternalLink() {
      this.loading = true;
      this.services.chart.deleteExternalLink(this.dataWidget.chartId, () => {
        this.externalLink = '';
        this.showNotification('Deleted external link.');
        this.loading = false;
      });
    },
    updateExternalLink(externalLink) {
      if (externalLink) this.externalLink = `${location.protocol}//${location.host}${externalLink}`;
      else this.externalLink = '';
    },
  },
};
</script>

<style scoped>
.dashboard-widget-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.filters {
  display: flex;
  justify-content: center;
  width: 100%;
}

.filters > * {
  width: 100%;
}

.test-status-checkboxes {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.external-link {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.external-link-text-field {
  flex-grow: 1;
}
</style>